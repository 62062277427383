var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mb-4",staticStyle:{"margin-top":"20px"}},[_c('modalEmpleado',{attrs:{"modal":_vm.showEmpleado},on:{"cerrarModal":function($event){_vm.showEmpleado=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('CRow',[_c('CCol',{attrs:{"sm":"4"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.payrollType'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'},"options":_vm.payrollOptions,"value":_vm.payrollTypeId,"invalid-feedback":_vm.$t('label.required')},on:{"change":function($event){return _vm.changeValue($event.target.value)},"update:value":function($event){_vm.payrollTypeId=$event}},model:{value:(_vm.payrollTypeId),callback:function ($$v) {_vm.payrollTypeId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"payrollTypeId"}})],1),_c('CCol',{attrs:{"sm":"4"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.company'),"horizontal":{ label: 'col-sm-4', input: 'col-sm-8'},"options":_vm.companyOptions,"value":_vm.companyId,"invalid-feedback":_vm.$t('label.required')},on:{"change":function($event){return _vm.changeCompanyOption($event.target.value)},"update:value":function($event){_vm.companyId=$event}},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyId"}})],1),_c('CCol',{attrs:{"sm":"4 text-right"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content:  _vm.$t('label.clearFilters'),
                    placement: 'top-end'
                }),expression:"{\n                    content:  $t('label.clearFilters'),\n                    placement: 'top-end'\n                }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"wipe"},on:{"click":_vm.limpiarDatos}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.exportToExcel'),
                    placement: 'top-end'
                }),expression:"{\n                    content: $t('label.exportToExcel'),\n                    placement: 'top-end'\n                }"}],staticStyle:{"margin-left":"5px"},attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.generarXLS(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XLSX ")],1)],1),(_vm.collapseItems)?_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.dateFilteredItems,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableTextHelpers.tableFilterText,"items-per-page-select":_vm.tableTextHelpers.itemsPerPageText,"noItemsView":_vm.tableTextHelpers.noItemsViewText,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[(item.status)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")]):_vm._e(),(!item.status)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")]):_vm._e()],1)]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.edit'),placement: 'top'}),expression:"{content: $t('label.edit'),placement: 'top'}"}],staticStyle:{"margin":"5px"},attrs:{"color":"edit","size":"sm"},on:{"click":function($event){_vm.showEmpleado=item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}],null,false,1229935385)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }